<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        {{ titleZh  }}
      </div>
      <div class="topic_desc_en">
        {{ titleEn  }}
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="main_panel">
      <div class="module_desc">
        {{thematicDesc}}
      </div>
      <div class="section_content">
        <div class="section_title section_title_range">服务内容</div>
        <div class="section_topic_content">
          <div class="main_item" v-for="(item,idx) in topics" :key="idx">
            <div class="main_item_topic">
              <span class="topic_text">{{item.desc}}</span>
            </div>
            <div class="arrow"></div>
          </div>
        </div>
        <div class="section_module_content">
          <div class="module_items" v-for="(item, idx) in modules" :key="idx">
            <div class="modules_icon">
              <img :src="item.icon" alt="">
            </div>
            <div class="modules_part">
              <div class="modules_title">
                {{item.title}}
              </div>
              <div class="modules_desc">
                {{item.desc}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main_panel">
      <div class="section_content">
        <div class="section_title section_title_feature">特色优势</div>
        <div class="section_card_content">
          <div class="section_card_left">
            <div class="range_topic">
              {{cards_left_title}}
            </div>
            <div class="range_items_content">
              <div class="section_card_item" v-for="(cItem,cIdx) in cards_left" :key="cIdx">
                <div class="section_card_line" v-for="(lItem,lIdx) in cItem" :key="lIdx">
                  <div class="section_card_icon"></div>
                  <div class="section_card_label">
                    {{lItem}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section_card_right">
            <div class="range_topic">
              {{cards_right_title}}
            </div>
            <div class="range_items_content">
              <div class="section_card_item" v-for="(cItem,cIdx) in cards_right" :key="cIdx">
                <div class="section_card_line" v-for="(lItem,lIdx) in cItem" :key="lIdx">
                  <div class="section_card_icon"></div>
                  <div class="section_card_label">
                    {{lItem}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data() {
    return {
      "thematicDesc": "",
      "topics": [],
      "cards_left_title":"",
      "cards_left": [],
      "cards_right_title":"",
      "cards_right": [],
      "modules": [],
      "titleZh":"",
      "titleEn":""
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取页面数据
    getData(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=safeService&secondaryTitle=registerForConsultation`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"{}";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.thematicDesc = configData.thematicDesc;
          _this.topics = configData.topics;
          _this.cards_left_title = configData.cards_left_title;
          _this.cards_left = configData.cards_left;
          _this.cards_right_title = configData.cards_right_title;
          _this.cards_right = configData.cards_right;
          _this.titleZh = configData.titleZh;
          _this.titleEn = configData.titleEn;
          _this.modules = configData.modules.map(i=>{
            return Object.assign({},i,{
              icon:`${window.PICSEVERURL}/${i.icon}`
            })
          })
        }else{
          _this.$notify({
            title: '失败',
            message: "研发咨询与注册申报数据请求失败",
            type: 'error'
          });
        }
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getData();
  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.business_sub_page {
  width: 100%;
  margin-top: 2.5rem;
  padding: 0 1.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .topic_panel {
    position: relative;
    margin-bottom: 3rem;
    .topic_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.5rem;
      color: #222222;
      line-height: 2.4rem;
      margin-bottom: 10px;
    }
    .topic_desc_en{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.2rem;
      color: #BCBDBE;
      line-height: 28px;
      margin-bottom: 1.4rem;
    }
    .topic_desc_line{
      width: 76px;
      height: 3px;
      background: #0086D1;
    }
  }
  .main_panel {
    width: 100%;
    margin-bottom: 1.5rem;
    .module_desc {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 1rem;
      color: #333333;
      line-height: 1.4rem;
      margin-bottom: 2rem;
    }
    .section_content {
      width: 100%;
      .section_title{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 1.2rem;
        color: #000000;
        height: 2rem;
        line-height: 2rem;
        padding-left: 3rem;
        box-sizing: border-box;
        &.section_title_feature{
          background: url('./images/titleBg@feature.png') 0 0 no-repeat;
          background-size: auto 34px;
          background-position: center left;
        }
        &.section_title_range{
          background: url('./images/titleBg@range.png') 0 0 no-repeat;
          background-size: auto 34px;
          background-position: center left;
          margin-bottom: 10px;
        }
      }
    }
    .section_module_content {
      margin-bottom: 1rem;
      width: 100%;
      .module_items {
        display: flex;
        margin-bottom: 1.2rem;
        // justify-content: space-between;
      }
      .modules_part {
        flex: 1;
        overflow: hidden;
        padding: 0.5rem 0 0 1rem;
      }
      .modules_icon {
        width: 4rem;
        height: 4rem;
        background-size: 100% auto;
        background-position: center left;
        // margin-right: 7px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .modules_title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 1rem;
        color: #333333;
        line-height: 1.4rem;
      }
      .modules_desc {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 1rem;
        color: #333333;
        line-height: 1.4rem;
        padding-top: 0.5rem;
      }
    }
  }
  .section_topic_content {
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    .main_item{
      display: flex;
      &:last-child{
        margin-right: 0;
        .arrow{
          display: none;
        }
      }
      .main_item_topic {
        padding: 0 0.5rem;
        height: 2rem;
        background: #0086d1;
        display: flex;
        align-items: center;
        border-radius: 1rem;
        margin-bottom: 1rem;
        .topic_text {
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 0.9rem;
          color: #ffffff;
          line-height: 1.4rem;
        }
      }
    }
  }
  .section_card_content {
    width: 100%;
  }
  .section_card_left {
    width: 100%;
    background: #f6f7f9;
    padding: 0.5rem;
    box-sizing: border-box;
    margin: 0.5rem 0;
    .range_topic {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.1rem;
      color: #0086d1;
      line-height: 1.7rem;
      margin-bottom: 0.5rem;
    }
    .range_items_content {
      // display: flex;
      // flex-wrap: wrap;
    }
    .section_card_item {
      flex: 1;
      overflow: hidden;
      background: #f6f7f9;
      box-sizing: border-box;
      .section_card_line {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 1rem;
        color: #333333;
        line-height: 1.4rem;
        display: flex;
        align-items: flex-start;
        margin-bottom: 0.5rem;
        .section_card_icon {
          width: 1rem;
          height: 1rem;
          background: url("./images/icon@checked.png") 0 0 no-repeat;
          background-size: 100% auto;
          background-position: center left;
          // margin: 0.5rem 7px 0.5rem 0;
          margin-right: 0.5rem;
        }
      }
    }
  }
  .section_card_right {
    width: 100%;
    background: #f6f7f9;
    padding: 0.5rem;
    box-sizing: border-box;
    margin: 0.5rem 0;
    .range_topic {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.1rem;
      color: #0086d1;
      line-height: 1.7rem;
      margin-bottom: 0.5rem;
    }
    .range_items_content {
      // display: flex;
      // flex-wrap: wrap;
    }
    .section_card_item {
      flex: 1;
      overflow: hidden;
      background: #f6f7f9;
      box-sizing: border-box;
      .section_card_line {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 1rem;
        color: #333333;
        line-height: 1.4rem;
        display: flex;
        align-items: flex-start;
        margin-bottom: 0.5rem;
        .section_card_icon {
          width: 1rem;
          height: 1rem;
          background: url("./images/icon@checked.png") 0 0 no-repeat;
          background-size: 100% auto;
          background-position: center left;
          // margin: 0.5rem 7px 0.5rem 0;
          margin-right: 0.5rem;
        }
      }
    }
  }
  .arrow {
    position: relative;
    width: 1.2rem;
    height: 2rem;
    background: url('./images/arrow.png') 0 0 no-repeat;
    background-size: 100% auto;
    background-position: center left;
    margin-bottom: 1rem;
  }
}
</style>